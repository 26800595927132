import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Clients.scss';
import { mod } from '../../../common/functions';

const Clients = ({ clients }) => { 
  const [currentIndex, setCurrentIndex] = useState(0);

  const retrievePositionClassFromIndex = (index) => {
    if (index === currentIndex) {
      return 'current';
    }

    if (index === mod(currentIndex - 1, clients.length)) {
      return 'previous';
    }

    if (index === mod(currentIndex + 1, clients.length)) {
      return 'next'
    } 
    
    return '';
  };

  const handleClick = (event) => {
    const classList = event.currentTarget.classList;

    if (classList.contains('previous')) {
      setCurrentIndex(mod(currentIndex - 1, clients.length))
    } else if (classList.contains('next')) {
      setCurrentIndex(mod(currentIndex + 1, clients.length));
    }
  };

	return (
    <section className="hero is-dark is-medium carousel">
      <div className="hero-body">
        <div className="container">
          <h1
            className="title has-text-white is-with-bar is-family-hakio"
            data-aos="fade-in"
          >
            Parcours client
          </h1>
          <p className="subtitle" data-aos="fade-in">
            Vous trouverez dans cette rubrique des résultats obtenus avec quelques un de mes clients.
          </p>
          <div className="cards" data-aos="fade-in" data-aos-delay="100">
            {
              clients.map((data, index) => (
                  <div
                    key={data.image}
                    className={`card ${retrievePositionClassFromIndex(index)}`}
                    onClick={handleClick} onKeyDown={handleClick}
                    role="presentation"
                  >
                    <div className="card-image">
                      <img src={data.image} alt="" />
                    </div>
                    <div className="card-content">
                      <div className="content has-text-white">
                        <p>{data.description}</p>
                        <p className="has-text-weight-bold">{data.name}</p>
                      </div>
                    </div>
                  </div>
                )
              )
            }
          </div>
        </div>
      </div>
    </section>
  );
};

Clients.propTypes = {
  clients: PropTypes.array.isRequired,
};

Clients.defaultProps = {
  clients: [],
};

export default Clients;